import { jsx as _jsx } from "react/jsx-runtime";
import { CommentOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import { createBrowserRouter, redirect } from 'react-router-dom';
import { extractRedirectAccessToken, getSSOLoginInternalPageAuthURL, getSSOLoginPageAuthURL } from '@ai-agent/util';
import { LazyPage } from '@component/lazy-page';
import { userService } from '@ai-agent/service';
const routes = [
    {
        path: '/',
        loader: () => {
            const token = extractRedirectAccessToken();
            if (token) {
                userService.injectAccessToken(token);
            }
            if (!userService.validAccessToken) {
                localStorage.clear();
                return redirect('/login/internal');
            }
            return true;
        },
        element: _jsx(LazyPage, { page: "layout" }),
        children: [
            {
                path: 'user',
                children: [
                    {
                        path: '',
                        loader: () => redirect('real'),
                    },
                    {
                        path: 'assistant',
                        element: _jsx(LazyPage, { page: "user" }),
                    },
                    {
                        path: 'real',
                        element: _jsx(LazyPage, { page: "user" }),
                    },
                    {
                        path: 'temp',
                        element: _jsx(LazyPage, { page: "user" }),
                    },
                ],
            },
            {
                path: 'message',
                element: _jsx(LazyPage, { page: "message" }),
            },
            {
                path: 'session',
                children: [
                    {
                        path: '',
                        loader: () => redirect('manage'),
                    },
                    {
                        path: 'manage',
                        element: _jsx(LazyPage, { page: "session/manage" }),
                    },
                    {
                        path: 'template',
                        element: _jsx(LazyPage, { page: "session/template" }),
                    },
                ],
            },
            {
                path: 'Test',
                children: [
                    {
                        path: '',
                        element: _jsx(LazyPage, { page: "test" }),
                    },
                ],
            },
            {
                path: '',
                loader: () => redirect('user'),
            },
        ],
    },
    {
        path: '/login/internal',
        loader: () => {
            localStorage.clear();
            const url = location.origin;
            return redirect(getSSOLoginInternalPageAuthURL(undefined, url));
        },
    },
    {
        path: '/login',
        loader: () => {
            localStorage.clear();
            const url = location.origin;
            return redirect(getSSOLoginPageAuthURL(undefined, url));
        },
    },
    {
        path: '*',
        loader: async () => redirect('/'),
    },
];
export const router = createBrowserRouter(routes);
export const menuItems = [
    {
        label: 'User',
        key: '/user',
        icon: _jsx(UserOutlined, {}),
        children: [
            {
                label: 'Assistant',
                key: '/user/assistant',
            },
            {
                label: 'Real',
                key: '/user/real',
            },
            {
                label: 'Temp',
                key: '/user/temp',
            },
        ],
    },
    {
        label: 'Message',
        key: '/message',
        icon: _jsx(MessageOutlined, {}),
    },
    {
        label: 'Session',
        key: '/session',
        icon: _jsx(CommentOutlined, {}),
        children: [
            {
                label: 'Manage',
                key: '/session/manage',
            },
            {
                label: 'Template',
                key: '/session/template',
            },
        ],
    },
    // {
    //   label: 'Test',
    //   key: '/test',
    //   icon: <BugOutlined />,
    // },
];
